<template>
    <Loading :isLoading="isLoading" />
    <div class="w-full h-full px-5 md:px-5 lg:px-10 pt-2 md:pt-5 lg:max-h-screen mb-5">
        <h1 class="primary-text font-semibold text-2xl md:text-2xl text-thin mt-2 mb-5 md:mb-7"> Datos de Crédito </h1>
        <CreditData ref="creditDataRefs" :validations="rules_validations" :view_class_view="view_class_view" :amount_list="amount_list" :deadline_list="deadline_list" :credit_destination_list="credit_destination_list" :payment_method_list="payment_method" />
        <div class="w-full">
            <button id="button_continue" @click="next('/cuenta/credito/propietario')" class="md:w-1/3 block bg-mustardDark cursor-pointer px-5 py-4 mx-auto my-8 h-14 md:h-14 rounded text-center font-normal text-xl text-white"> Continuar </button>       
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onBeforeMount } from 'vue';
import { helpers, requiredIf } from "@vuelidate/validators";
import { useRouter } from 'vue-router';

import Loading from '@/components/Loading/VueLoading.vue';

export default {
    name: 'CreditDataComponent',
    setup(){

        const router = useRouter();

        let isLoading = ref(true);

        let creditDataRefs = ref(null);

        let view_class_view = {
            form_container: "",
            input_container: "",
            class_inputs:{
                valid:"border-blue-600",
                no_valid:"border-red-600",
                default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
            },
            div_container_error:{
                container:"my-2",
                text_error:"text-red-600",
            },
        };

        let rules_validations = {
            amount: {
                required: helpers.withMessage('El campo es requerido', requiredIf(true))
            },
            frecuency: {
                required: helpers.withMessage('El campo es requerido', requiredIf(true))
            },
            deadline: {
                required: helpers.withMessage('El campo es requerido', requiredIf(true)) 
            },
            credit_destination: {
                required: helpers.withMessage('El campo es requerido', requiredIf(true)) 
            },
            payment_method: {
                required: helpers.withMessage('El campo es requerido', requiredIf(true))
            },
        };

        let amount_list = [
            100,
            200,
            300,
            400,
            500,
            600
        ];

        let deadline_list = [
            "3 meses",
            "6 meses", 
            "1 año",
            "2 años"
        ];

        let credit_destination_list = [
            "Pago de adeudos",
            "Compra de vehiculos"
        ];

        let payment_method = [
            "Efectivo",
            "Tarjeta de crédito"
        ];

        onBeforeMount(() => {
            let storage = existStorage();
            if(storage) deleteStorage();
        });

        onMounted(() => {
            isLoading.value = false;
        });

        function next(route) {
            let creditData = creditDataRefs.value.execute_validation();
            if(creditData) {
                createStorage(creditData);
                router.push({ path: route });
                return;
            }
        }

        function existStorage(){
            return sessionStorage.getItem('credit-data');
        }

        function deleteStorage(){
            return sessionStorage.removeItem('credit-data')
        }

        function createStorage(data){
            sessionStorage.setItem('credit-data', JSON.stringify(data));
        }

        return {
            isLoading,
            view_class_view,
            rules_validations,
            amount_list,
            deadline_list,
            credit_destination_list,
            payment_method,
            creditDataRefs,
            next
        };
    },
    components:{
        Loading
    }
}

</script>

<style scoped>
p {
    color: #485462;
}
</style>