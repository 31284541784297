<template>
    <html>
      <body class="" style="background: #FFF">
        <HeaderMobil />
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
           
            <div class="col-span-6">
              <CreditDataComponent />
            </div>
            
        </div>
        <!--<Footer/>-->
      </body>
    </html>
</template>

<script>

import MenuPrincipal from "@/components/Cuenta/MenuPrincipal.vue"
import CreditDataComponent from "@/components/Cuenta/Credit/CreditDataComponent.vue"
import HeaderMobil from "@/components/Cuenta/HeaderMobil.vue"
import MenuLateral from '@/components/LateralMenu.vue';
import Footer from '@/components/Footer.vue'

export default {
  name: 'CreditData',
  components: {
    MenuPrincipal,
    CreditDataComponent,
    HeaderMobil,
    MenuLateral,
    Footer
  }
}
</script>